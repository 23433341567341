import React, { useState, useEffect } from "react"
import { fetchPageContents } from "../components/utilities"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ContributorForm from '../components/forms/contributor-form'
import CorrectionForm from '../components/forms/correction-form'
import DocumentForm from '../components/forms/document-form'
import PeriodicalForm from '../components/forms/periodical-form'
import { Tab } from 'semantic-ui-react'

const Submissions = () => {
  const [pageContents, setPageContents] = useState("");

  useEffect(() => {
    fetchPageContents("submissions")
      .then(text => setPageContents(text));
  },[]);

  return (
    <Layout>
      <Seo title="Submissions" />
      <div>
        <div align="center">&nbsp;</div>

        <div align="center"><font size="5"><strong>Submissions</strong></font></div>

        <div>&nbsp;</div>

        <div className="submissions">
          <div className="form-sidebar">
          <font color="#333333">
          <div
            dangerouslySetInnerHTML={{__html: pageContents}}>
          </div>
          </font>
        </div>

          <div className="form panel-default panel-body">
            <div id="form-success-alert" className="alert alert-success alert-dismissible hidden" role="alert">
              <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
              Form submitted
            </div>

            <div id="form-error-alert" className="alert alert-danger alert-dismissible hidden" role="alert">
              <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            </div>

            <Tab panes={[
              { menuItem: 'Document', render: () => (
                  <Tab.Pane>
                    <h4>Document Submission</h4>
                    <DocumentForm />
                  </Tab.Pane>
              )},
              { menuItem: 'Contributor', render: () => (
                  <Tab.Pane>
                    <h4>Contributor Submission</h4>
                    <ContributorForm />
                  </Tab.Pane>
              )},
              { menuItem: 'Periodical', render: () => (
                  <Tab.Pane>
                    <h4>Periodical Submission</h4>
                    <PeriodicalForm />
                  </Tab.Pane>
              )},
              { menuItem: 'Correction', render: () => (
                  <Tab.Pane>
                    <h4>Correction Submission</h4>
                    <CorrectionForm />
                  </Tab.Pane>
              )},
            ]} />

          </div>

      </div>
      </div>
    </Layout>
  )
}

export default Submissions
