import * as React from "react";
import { handleFormSubmit } from './formSubmission.js';
import {
  Button,
  Form,
  Input,
  TextArea
} from 'semantic-ui-react';

const ContributorForm = () => (
  <>
    <div><span className="required">*</span><em> = required field</em></div>

    <br />

    <Form id="corrections-form" onSubmit={handleFormSubmit('corrections')}>
      <Form.Field>
        <label>Contact Name:<span className="required">*</span></label>
        <Input required type="text" name="contact_name" required />
      </Form.Field>

      <Form.Field>
        <label>Contact Email:<span className="required">*</span></label>
        <Input required type="email" name="contact_email" required />
      </Form.Field>

      <Form.Field>
        <label>Link to <em>Curran Index</em> page OR vol. and page no. in <em>Wellesley</em>:</label>
        <Input type="text" name="correction_link" />
      </Form.Field>

      <Form.Field>
        <label>Error:</label>
        <TextArea type="text" name="error" />
      </Form.Field>

      <Form.Field>
        <label>Correction:</label>
        <TextArea type="text" name="correction" />
      </Form.Field>

      <Form.Field>
        <label>Rationale:</label>
        <TextArea type="text" name="rationale" />
      </Form.Field>

      <Form.Button type="submit" value="Submit">Submit</Form.Button>
    </Form>

  </>
)

export default ContributorForm
