import React, { useEffect, useState } from "react"
import {
  fetchList,
  handleFormSubmit
} from './formSubmission.js'
import {
  Button,
  Dropdown,
  Form,
  Input,
  TextArea
} from 'semantic-ui-react';

const PeriodicalForm = () => {
  const [frequencyOptions, setFrequencyOptions] = useState([]);
  const [frequencySelections, setFrequencySelections] = useState([]);

  useEffect(() => {
    fetchList('frequencies', setFrequencyOptions);
  },[])

  const handleSubmit = handleFormSubmit(
    // collection
    'periodicals_drafts',
    // formProps
    [
      ["frequencies", frequencySelections.map(selection => (
        { frequencies_id: Number.isInteger(selection) ? { id: selection } : { value: selection } }
      ))]
    ],
    // clearFunctions
    [
      () => setFrequencySelections([]),
    ]
  );

  const handleFrequencyAddition = (value) => {
    setFrequencyOptions([
      ...frequencyOptions,
      {
        key: value,
        text: value,
        value: value
      }
    ])
  };

  return (
    <>
      <div><span className="required">*</span><em> = required field</em></div>

      <br />

      <Form id="periodicals_drafts-form" onSubmit={handleSubmit}>
        <Form.Field required>
          <label>Contact Name:</label>
          <Input required type="text" name="contact_name" />
        </Form.Field>

        <Form.Field required>
          <label>Contact Email:</label>
          <Input required type="email" name="contact_email" />
        </Form.Field>

        <Form.Field>
          <label>Title:</label>
          <Input type="text" name="title" />
        </Form.Field>

        <Form.Field>
          <label>Description:</label>
          <TextArea type="text" name="comment" />
        </Form.Field>

        <Form.Field>
          <label>Frequency:</label>
          <Dropdown
            allowAdditions
            fluid
            multiple
            name="frequencies"
            onAddItem={(e, { value }) => handleFrequencyAddition(value)}
            onChange={(e, { value }) => setFrequencySelections(value)}
            options={frequencyOptions}
            placeholder='Choose Frequency...'
            search
            selection
            value={frequencySelections}
          />
        </Form.Field>

        <Form.Field>
          <label>Additional Comments:</label>
          <TextArea type="text" name="additional_comments" />
        </Form.Field>

        <Button type="submit">Submit</Button>
      </Form>

    </>
  )
}

export default PeriodicalForm
