import React, { useEffect, useState } from "react"
import {
  fetchList,
  handleFormSubmit
} from './formSubmission.js'
import {
  Button,
  Dropdown,
  Form,
  Input,
  Select,
  TextArea
} from 'semantic-ui-react';

const ContributorForm = () => {
  const [genderOptions, setGenderOptions] = useState([]);
  const [genderSelection, setGenderSelection] = useState('');
  const [nationalityOptions, setNationalityOptions] = useState([]);
  const [nationalitySelections, setNationalitySelections] = useState([]);
  const [schoolOptions, setSchoolOptions] = useState([]);
  const [schoolSelections, setSchoolSelections] = useState([]);

  useEffect(() => {
    fetchList('genders', setGenderOptions);
    fetchList('nationalities', setNationalityOptions);
    fetchList('schools', setSchoolOptions)
  },[])

  const handleSubmit = handleFormSubmit(
    // collection
    'contributors_drafts',
    // formProps
    [
      ["gender", genderSelection],
      ["nationalities", nationalitySelections.map(selection => (
        { nationalities_id: Number.isInteger(selection) ? { id: selection } : { name: selection } }
      ))],
      ["schools", schoolSelections.map(selection => (
        { schools_id: Number.isInteger(selection) ? { id: selection } : { name: selection } }
      ))]
    ],
    // clearFunctions
    [
      () => setGenderSelection(''),
      () => setNationalitySelections([]),
      () => setSchoolSelections([])
    ]
  );

  const handleNationalityAddition = (value) => {
    setNationalityOptions([
      ...nationalityOptions,
      {
        key: value,
        text: value,
        value: value
      }
    ])
  };

  const handleSchoolAddition = (value) => {
    setSchoolOptions([
      ...schoolOptions,
      {
        key: value,
        text: value,
        value: value
      }
    ])
  };

  return (
    <>
      <div>
        <font color="#333333">For contributors who are not currently present in the <em>Index</em>, please provide as much information as possible, including a short biographical statement (c. 50-100 words). You may also include which university they attended (optional). Please provide dates in the format DD/MM/YYYY.</font>
      </div>

      <div>&nbsp;</div>

      <div><span className="required">*</span><em> = required field</em></div>

      <br />

      <Form id="contributors_drafts-form" onSubmit={handleSubmit}>
        <Form.Field required>
          <label>Contact Name:</label>
          <Input required type="text" name="contact_name" />
        </Form.Field>

        <Form.Field required>
          <label>Contact Email:</label>
          <Input required type="email" name="contact_email" />
        </Form.Field>

        <Form.Field>
          <label>Full Name:</label>
          <Input type="text" name="full_name" />
        </Form.Field>

        <Form.Field>
          <label>Birth Year:</label>
          <Input type="text" name="birth_year" />
        </Form.Field>

        <Form.Field>
          <label>Death Year:</label>
          <Input type="text" name="death_year" />
        </Form.Field>

        <Form.Field>
          <label>Gender:</label>
          <Dropdown
           options={genderOptions}
           placeholder='Choose Gender...'
           selection
           fluid
           value={genderSelection}
           onChange={(e, { value }) => setGenderSelection(value)}
         />
        </Form.Field>

        <Form.Field>
          <label>Nationality:</label>
          <Dropdown
            allowAdditions
            fluid
            multiple
            name="nationalities"
            onAddItem={(e, { value }) => handleNationalityAddition(value)}
            onChange={(e, { value }) => setNationalitySelections(value)}
            options={nationalityOptions}
            placeholder='Choose Nationality...'
            search
            selection
            value={nationalitySelections}
          />
        </Form.Field>

        <Form.Field>
          <label>Biography:</label>
          <TextArea type="text" name="biography" />
        </Form.Field>

        <Form.Field>
          <label>University:</label>
          <Dropdown
            allowAdditions
            fluid
            multiple
            name="schools"
            onAddItem={(e, { value }) => handleSchoolAddition(value)}
            onChange={(e, { value }) => setSchoolSelections(value)}
            options={schoolOptions}
            placeholder='Choose University...'
            search
            selection
            value={schoolSelections}
          />
        </Form.Field>

        <Form.Field>
          <label>Additional Comments:</label>
          <TextArea type="text" name="additional_comments" />
        </Form.Field>

        <Button type="submit">Submit</Button>
      </Form>
    </>
  )
}

export default ContributorForm
