const postData = async (url = '', data = {}) => {
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json'
    },
    redirect: 'manual',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(data)
  });
  return response.json();
};

export const handleFormSubmit = (collection, formPropsArray = [], clearFunctions) => event => {
  event.preventDefault();
  const formData = new FormData(event.currentTarget);
  const formProps = Object.fromEntries([
    ...formData,
    ...formPropsArray
  ]);

  postData(`https://admin.curranindex.org/items/${collection}`, formProps)
    .then(data => {
      if (data.errors) {
        data.errors.forEach((error) => {
          document.getElementById("form-error-alert").innerHTML += `<p>${error.message}</p>`
        })
        document.getElementById("form-error-alert").classList.remove("hidden");
      } else {
        document.getElementById(`${collection}-form`).reset();
        document.getElementById("form-success-alert").classList.remove("hidden");
      }
    })
    .then(() => clearFunctions.forEach(f => f()));
};

export const fetchList = (listName, setter, sortField = '') => {
  fetch(`https://admin.curranindex.org/items/${listName}${sortField}`)
    .then(response => response.json())
    .then(listItems => setter(listItems.data.map(li => ({
      key: li.id,
      text: listName === "nationalities" || listName === "schools" ? li.name : listName === "periodicals" ? li.title : li.value,
      value: listName === "genders" || listName === "attrribution_confidence" ? li.value : li.id
    }))));
}
