import React, { useEffect, useState } from "react"
import {
  fetchList,
  handleFormSubmit
} from './formSubmission.js'
import {
  Button,
  Dropdown,
  Form,
  Input,
  Select,
  TextArea
} from 'semantic-ui-react';

const DocumentForm = () => {
  const [articleTypeOptions, setArticleTypeOptions] = useState([]);
  const [articleTypeSelections, setArticleTypeSelections] = useState([]);
  const [attrConfidenceOptions, setAttrConfidenceOptions] = useState([]);
  const [attrConfidenceSelection, setAttrConfidenceSelection] = useState('');
  const [periodicalOptions, setPeriodicalOptions] = useState([]);
  const [periodicalSelections, setPeriodicalSelections] = useState([]);

  useEffect(() => {
    fetchList('article_types', setArticleTypeOptions);
    fetchList('attribution_confidence', setAttrConfidenceOptions);
    fetchList('periodicals', setPeriodicalOptions, '?sort=title');
  },[])

  const handleSubmit = handleFormSubmit(
    // collection
    'documents_drafts',
    // formProps
    [
      ["attribution_confidence", attrConfidenceSelection],
      ["article_types", articleTypeSelections.map(selection => (
        { article_types_id: Number.isInteger(selection) ? { id: selection } : { value: selection } }
      ))],
      ["periodicals", periodicalSelections.map(selection => (
        { periodicals_id: Number.isInteger(selection) ? { id: selection } : { title: selection } }
      ))]
    ],
    // clearFunctions
    [
      () => setArticleTypeSelections([]),
      () => setAttrConfidenceSelection(''),
      () => setPeriodicalSelections([])
    ]
  );

  const handleArticleTypeAddition = (value) => {
    setArticleTypeOptions([
      ...articleTypeOptions,
      {
        key: value,
        text: value,
        value: value
      }
    ])
  };

  const handlePeriodicalAddition = (value) => {
    setPeriodicalOptions([
      ...periodicalOptions,
      {
        key: value,
        text: value,
        value: value
      }
    ])
  };

  return (
    <>
      <div><span className="required">*</span><em> = required field</em></div>

      <br />

      <Form id="documents_drafts-form" onSubmit={handleSubmit}>

        <Form.Field required>
          <label>Contact Name:</label>
          <Input required type="text" name="contact_name" />
        </Form.Field>

        <Form.Field required>
          <label>Contact Email:</label>
          <Input required type="email" name="contact_email" />
        </Form.Field>

        <Form.Field>
          <label>Periodical Title:</label>
          <Dropdown
            allowAdditions
            fluid
            multiple
            name="periodicals"
            onAddItem={(e, { value }) => handlePeriodicalAddition(value)}
            onChange={(e, { value }) => setPeriodicalSelections(value)}
            options={periodicalOptions}
            placeholder='Choose Periodical...'
            search
            selection
            value={periodicalSelections}
          />
        </Form.Field>

        <Form.Field>
          <label>Vol. (number or year):</label>
          <Input type="number" min="0" name="volume" />
        </Form.Field>

        <Form.Field>
          <label>Issue:</label>
          <Input type="number" min="0" name="issue_number" />
        </Form.Field>

        <Form.Field>
          <label>Year:</label>
          <Input type="number" min="0" max="9999" name="article_year" />
        </Form.Field>

        <Form.Field>
          <label>Month (1-12):</label>
          <Input type="number" min="0" max="12" name="month_id" />
        </Form.Field>

        <Form.Field>
          <label>Day (1-31):</label>
          <Input type="number" min="0" max="31" name="day" />
        </Form.Field>

        <Form.Field>
          <label>Article Title:</label>
          <Input type="text" name="title" />
        </Form.Field>

        <Form.Field>
          <label>Type (prose, review, verse, etc.):</label>
          <Dropdown
            allowAdditions
            fluid
            multiple
            name="article_types"
            onAddItem={(e, { value }) => handleArticleTypeAddition(value)}
            onChange={(e, { value }) => setArticleTypeSelections(value)}
            options={articleTypeOptions}
            placeholder='Choose Type...'
            search
            selection
            value={articleTypeSelections}
          />
        </Form.Field>

        <Form.Field>
          <label>Page Start:</label>
          <Input type="number" min="0" name="page_start" />
        </Form.Field>

        <Form.Field>
          <label>Page End:</label>
          <Input type="number" min="0" name="page_end" />
        </Form.Field>

        <Form.Field>
          <label>Full Name:</label>
          <Input type="text" name="full_name" />
        </Form.Field>

        <Form.Field>
          <label>Payment (£sd):</label>
          <Input type="text" name="payment" />
        </Form.Field>

        <Form.Field>
          <label>Source:</label>
          <Input type="text" name="attribution" />
        </Form.Field>

        <Form.Field>
          <label>Attribution Confidence (low/medium/high):</label>
          <Dropdown
            options={attrConfidenceOptions}
            placeholder='Choose Confidence Level...'
            selection
            fluid
            value={attrConfidenceSelection}
            onChange={(e, { value }) => setAttrConfidenceSelection(value)}
           />
        </Form.Field>

        <Form.Field>
          <label>Additional Comments:</label>
          <TextArea name="additional_comments" />
        </Form.Field>

        <Button type="submit">Submit</Button>
      </Form>
    </>
  )
}

export default DocumentForm
